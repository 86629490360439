const controls = new Map();

export function getControl(pTreeObject){
    if(!controls.has(pTreeObject.id)){
        controls.set(pTreeObject.id,new OrgunitsTreeControl(pTreeObject));
    }

    return controls.get(pTreeObject.id);
}

export default class OrgunitsTreeControl{
    idPathMap:Set<number> = new Set();
    treeObject:any;
    private _data:Array<any>;
    constructor(pTreeObject){
        this.treeObject = pTreeObject;
    }

    /**Leftover would show only top ones, not init all nodes */
    updateIdPaths(pData){
        this.idPathMap.clear();
        pData.forEach(path=>{
            path["IdPath"].split("/").forEach(id=>{
                if(id && !this.idPathMap.has(id)){
                    this.idPathMap.add(id);
                }
            })
        });
        const array = Array.from(this.idPathMap);

        this.treeObject.reloadSearchString(`[ID] IN (${array.join()})`);

    }

    getIdPathInString(pData){
        this.idPathMap.clear();
        pData.forEach(path=>{
            path["IdPath"].split("/").forEach(id=>{
                if(id && !this.idPathMap.has(id)){
                    this.idPathMap.add(id);
                }
            })
        });
        
        return `[ID] IN (${Array.from(this.idPathMap).join()})`;
    }

    addChildNodes(pNode){
        const vData = pNode.parent?this._data.filter(x=>x.Parent_ID==pNode.item.ID):this._data.filter(x=>x.Parent_ID==null);
        pNode.addChildren(vData);
        pNode.children.forEach(child=>{
            this.addChildNodes(child);
        })
    }

    async doSearch(searchString:string){
  
        this.treeObject.resetNode();
        if(!searchString){
            
            return this.treeObject.node.initChildren();
  
        }
        return this.treeObject.retrieve({
            filterString:`[SearchColumn] LIKE '%${searchString}%'`,
            fields:[{name:"IdPath"}]
        }).then(pData=>{
            //this.updateIdPaths(pData);
            if(pData.length === 0){

                return;
            }
            return this.treeObject.retrieve({
                whereClause:this.getIdPathInString(pData)
            }).then(pRows=>{
                this._data = pRows;
                this.addChildNodes(this.treeObject.node);
                
            })

        });
    }
}